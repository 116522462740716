import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import Avatar from '@mui/material/Avatar';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../sections/@dashboard/user';
import USERLIST from '../_mock/user';
import { useStyles } from 'src/styles/components/styles';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import { useNavigate } from 'react-router-dom';
import { MESSAGES } from 'src/constants/ResponseMessages';

const TABLE_HEAD = [{ id: 'name', label: 'Student Name', alignRight: false }];

export default function GoogleStudents(props) {
    const classes = useStyles();
    const [studentList] = useState(props.students);
    const navigate = useNavigate();
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [filterName] = useState('');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = USERLIST.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const filteredUsers = studentList;

    const isUserNotFound = filteredUsers.length === 0;


    return (
        <Card>
            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                        <UserListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={studentList.length}
                            numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                            {filteredUsers && filteredUsers?.map((row) => {
                                const { id, profile } = row;
                                const isItemSelected = selected.indexOf(profile.name.fullName) !== -1;
                                return (
                                    <TableRow
                                        hover
                                        key={id}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={isItemSelected}
                                        aria-checked={isItemSelected}
                                        onClick={() => props?.checkAssignments ? navigate(`/dashboard/student-assignments/${props?.classroomId}/${profile.id}/${profile.name.fullName}`) :  props?.assignmentId && navigate(`/whiteboard/${props?.classroomId}/${props?.assignmentId}/${profile.id}`)}
                                    >
                                        <TableCell>
                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                <Avatar className={classes.avatar} alt={profile.name.fullName} src=''>{profile.name.fullName.split(' ')[0][0]}{profile.name.fullName.split(' ')[1][0]}</Avatar>
                                                <div className={classes.flexAlignCenter}>
                                                    <Typography variant='subtitle2' noWrap>
                                                        {profile.name.fullName}
                                                    </Typography>
                                                    <ErrorOutlinedIcon style={{ color: 'red' }} />
                                                </div>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        {isUserNotFound && (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                        <SearchNotFound searchQuery={filterName} desc="No Student Added." />
                                        <p className={classes.notFoundSubHeading}>{MESSAGES.ALLOW_SCOPES}</p>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Scrollbar>
        </Card>
    );
}
